import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  TutorDashboardCohortSessionStudentAttendance,
  TutorDashboardCohortSessionStudentGrading,
} from "@generated/graphql";
import {
  elaGradeOptions,
  generalGradeOptions,
  GRADE_TYPE_TO_ABSENT_TYPE_MAP,
} from "components/shared/AttendanceGrades/GradingPanel/constants";

export const getIsEvaluationComplete = (
  gradingEnabled: boolean,
  subject: CohortAssignmentSubject,
  attendance?: TutorDashboardCohortSessionStudentAttendance | null,
  grading?: Partial<TutorDashboardCohortSessionStudentGrading> | null
) => {
  const { Absent, Unknown } = CohortSessionStudentAttendanceStatus;
  if (!attendance || (gradingEnabled && !grading)) return false;
  if (attendance.status === Absent) return true;

  const attendanceIsTaken = attendance.status !== Unknown;

  let gradingIsTaken = !gradingEnabled;

  if (gradingEnabled && grading) {
    const isELA = subject === CohortAssignmentSubject.Ela;
    const gradeOptions = isELA ? elaGradeOptions : generalGradeOptions;

    gradingIsTaken = gradeOptions.every((gradeType) => {
      const absentKey = GRADE_TYPE_TO_ABSENT_TYPE_MAP[gradeType];
      const hasGrade = grading[gradeType] !== null;
      return absentKey === null ? hasGrade : hasGrade || grading[absentKey];
    });
  }

  return attendanceIsTaken && gradingIsTaken;
};
