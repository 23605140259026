import { SECOND_MS } from "@utils/dateTime";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../Icon";
import { Tooltip } from "../../Tooltip";

type Props = {
  hasConcerns: boolean;
  canCreateReport: boolean;
  hasSessionReport: boolean;
  sessionEndDateTime: number;
  onViewSessionReport?: () => void;
};

export const SessionReportDetails = ({
  hasConcerns,
  canCreateReport,
  hasSessionReport,
  sessionEndDateTime,
  onViewSessionReport,
}: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSessionOver, setIsSessionOver] = useState(false);

  useEffect(() => {
    if (isSessionOver) return;

    const now = Date.now();
    if (sessionEndDateTime > 0 && sessionEndDateTime <= now) {
      setIsSessionOver(true);
    } else if (sessionEndDateTime > 0) {
      timeoutRef.current = setTimeout(
        () => setIsSessionOver(true),
        sessionEndDateTime - now + SECOND_MS
      );
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [isSessionOver, sessionEndDateTime]);

  return (
    <div
      className="flex flex-center h-full"
      onClick={() =>
        (canCreateReport || hasSessionReport) &&
        isSessionOver &&
        onViewSessionReport?.()
      }
    >
      <Tooltip
        tooltipProps={{ place: "left" }}
        className={clsx(
          "relative flex gap-x-1.5",
          !hasSessionReport && "!cursor-default"
        )}
        content={
          <div className="max-w-[420px] text-center leading-none">
            {hasSessionReport ? (
              <div className="text-base text-emerald-600 font-bold">
                View Session Report
              </div>
            ) : (
              <div className="text-sm">
                {canCreateReport && isSessionOver ? (
                  "Submit Session Report"
                ) : (
                  <>
                    <div className="text-base leading-5 text-rose-600 font-bold">
                      No Session Report Submitted
                    </div>
                    <div className="text-sm leading-none mt-1">
                      {isSessionOver
                        ? `This session's TT has not yet submitted this session report.`
                        : `TT's can only submit session reports once the session is over.`}
                    </div>
                  </>
                )}
              </div>
            )}
            {hasConcerns && (
              <div className="font-sm">This report has session concerns.</div>
            )}
          </div>
        }
      >
        <Icon
          icon="document"
          size={6}
          color={clsx(
            hasSessionReport
              ? "text-emerald-500"
              : canCreateReport && isSessionOver
              ? "text-rose-500"
              : "text-gray-400"
          )}
        />
        {hasConcerns && (
          <div className="absolute top-[1px] left-[13px] h-2 w-2 rounded-full bg-rose-700" />
        )}
      </Tooltip>
    </div>
  );
};
