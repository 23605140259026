import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  SessionConcernType,
  SessionReportInstructionType,
  SessionReportModal_SessionReportModalDataFragment,
  YesNoSomewhat,
} from "@generated/graphql";
import { StudentEvaluationRow } from "components/shared/AttendanceGrades/SessionStudentEvaluationTable";
import { getIsEvaluationComplete } from "../utils";
import {
  InstructionData,
  SessionConcernData,
  StudentPerformanceMap,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "./types";

export const syncModalData = (
  setInstructionData: (data: InstructionData) => void,
  setConcernsData: (data: SessionConcernData) => void,
  setStudentPerformanceMap: (data: StudentPerformanceMap) => void,
  csr: SessionReportModal_SessionReportModalDataFragment["sessionReport"] | null
) => {
  if (csr) {
    const { sessionConcerns, studentPerformance, learningTarget } = csr;
    setInstructionData({
      classCode: csr.classCode ?? null,
      strand: (csr.strand as VIMMathStrands) ?? null,
      instructionNotes: csr.instructionNotes ?? null,
      learningTarget: (learningTarget as WIMMathLearningTargets) ?? null,
      priorityStandard:
        (csr.priorityStandard as VIMElaPriorityStandards) ?? null,
    });
    setConcernsData({
      hasConcerns: sessionConcerns.length > 0,
      concerns: sessionConcerns.map((sc) => ({
        concernType: sc.concernType ?? null,
        concernNotes: sc.concernNotes ?? null,
        studentId: sc.studentId ? String(sc.studentId) : null,
      })),
    });
    const performanceMap = {} as StudentPerformanceMap;
    studentPerformance.forEach((sp) => {
      performanceMap[sp.studentId] = {
        mastery: sp.mastery,
        engagement: sp.engagement,
        performanceNotes: sp.performanceNotes ?? null,
      };
    });
    setStudentPerformanceMap(performanceMap);
  }
};

export const isInstructionDataValid = (
  instructionData: InstructionData,
  instructionType?: SessionReportInstructionType | null
): boolean => {
  const { strand, learningTarget, instructionNotes } = instructionData;
  const type = instructionType ?? SessionReportInstructionType.Default;

  switch (type) {
    case SessionReportInstructionType.VimMath:
      return !!learningTarget && !!strand;
    case SessionReportInstructionType.VimEla:
      return !!instructionData.priorityStandard;
    case SessionReportInstructionType.Vsup:
      return !!instructionData.classCode;
    case SessionReportInstructionType.Default:
      return !!instructionNotes && instructionNotes !== "";
  }
};

export const isStudentPerformanceValid = (
  studentPerformance: StudentPerformanceMap,
  attendedStudents: StudentEvaluationRow[]
): boolean =>
  attendedStudents.every(
    (s) =>
      !!studentPerformance[s.studentId] &&
      !!studentPerformance[s.studentId].mastery &&
      !!studentPerformance[s.studentId].engagement
  );

export const getSaveData = (
  sessionReportId: string | null,
  cohortSessionId: string,
  instructionData: InstructionData,
  concernsData: SessionConcernData,
  attendedStudents: StudentEvaluationRow[],
  studentPerformanceMap: StudentPerformanceMap
) => ({
  input: {
    id: sessionReportId,
    cohortSessionId,
    ...instructionData,
    sessionConcerns:
      concernsData.hasConcerns && (concernsData.concerns ?? []).length > 0
        ? (concernsData.concerns ?? []).map((c) => ({
            studentId: c.studentId ?? null,
            concernNotes: c.concernNotes ?? "",
            concernType: c.concernType ?? SessionConcernType.Other,
          }))
        : [],
    studentPerformance: attendedStudents.map(({ studentId }) => {
      const studentPerformance = studentPerformanceMap[studentId];
      const { engagement, performanceNotes } = studentPerformance;

      return {
        studentId,
        performanceNotes: performanceNotes ?? null,
        engagement: engagement ?? YesNoSomewhat.Somewhat,
        mastery: studentPerformance.mastery ?? YesNoSomewhat.Somewhat,
      };
    }),
  },
});

export const getStudentData = (
  studentRows?: SessionReportModal_SessionReportModalDataFragment["studentRows"],
  evaluationDetails?: SessionReportModal_SessionReportModalDataFragment["evaluationDetails"],
  sessionDetails?: SessionReportModal_SessionReportModalDataFragment["sessionDetails"]
) => {
  const subject = sessionDetails?.subject ?? CohortAssignmentSubject.General;
  const studentEvaluationRows = (studentRows ?? []).map((row) => ({
    ...row,
    isEvaluationComplete: getIsEvaluationComplete(
      evaluationDetails?.isGradingEnabled ?? false,
      subject,
      row.attendance,
      row.grading
    ),
  }));

  return {
    studentEvaluationRows,
    attendedStudents: studentEvaluationRows.filter(
      ({ attendance }) =>
        attendance?.status &&
        attendance.status !== CohortSessionStudentAttendanceStatus.Absent &&
        attendance.status !== CohortSessionStudentAttendanceStatus.Unknown
    ),
  };
};

export const getSessionButtonStyles = (submitted: boolean) =>
  submitted
    ? "bg-emerald-500 hover:bg-emerald-600 group-hover:bg-emerald-600"
    : "bg-rose-500 hover:bg-rose-600 group-hover:bg-rose-600";
